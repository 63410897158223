exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-happy-house-shifting-js": () => import("./../../../src/pages/happy-house-shifting.js" /* webpackChunkName: "component---src-pages-happy-house-shifting-js" */),
  "component---src-pages-happy-moving-js": () => import("./../../../src/pages/happy-moving.js" /* webpackChunkName: "component---src-pages-happy-moving-js" */),
  "component---src-pages-hire-a-mini-truck-js": () => import("./../../../src/pages/hire-a-mini-truck.js" /* webpackChunkName: "component---src-pages-hire-a-mini-truck-js" */),
  "component---src-pages-house-shifting-js": () => import("./../../../src/pages/house-shifting.js" /* webpackChunkName: "component---src-pages-house-shifting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-relocation-js": () => import("./../../../src/pages/international-relocation.js" /* webpackChunkName: "component---src-pages-international-relocation-js" */),
  "component---src-pages-international-relocation-services-js": () => import("./../../../src/pages/international-relocation-services.js" /* webpackChunkName: "component---src-pages-international-relocation-services-js" */),
  "component---src-pages-packers-and-movers-js": () => import("./../../../src/pages/packers-and-movers.js" /* webpackChunkName: "component---src-pages-packers-and-movers-js" */),
  "component---src-pages-storage-facility-js": () => import("./../../../src/pages/storage-facility.js" /* webpackChunkName: "component---src-pages-storage-facility-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-vehicle-shifting-js": () => import("./../../../src/pages/vehicle-shifting.js" /* webpackChunkName: "component---src-pages-vehicle-shifting-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-cobranded-pages-js": () => import("./../../../src/templates/cobrandedPages.js" /* webpackChunkName: "component---src-templates-cobranded-pages-js" */),
  "component---src-templates-domestic-intercity-js": () => import("./../../../src/templates/domesticIntercity.js" /* webpackChunkName: "component---src-templates-domestic-intercity-js" */),
  "component---src-templates-generic-js": () => import("./../../../src/templates/generic.js" /* webpackChunkName: "component---src-templates-generic-js" */),
  "component---src-templates-house-shifting-js": () => import("./../../../src/templates/houseShifting.js" /* webpackChunkName: "component---src-templates-house-shifting-js" */),
  "component---src-templates-packers-and-movers-js": () => import("./../../../src/templates/packersAndMovers.js" /* webpackChunkName: "component---src-templates-packers-and-movers-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */),
  "component---src-templates-truck-hire-js": () => import("./../../../src/templates/truckHire.js" /* webpackChunkName: "component---src-templates-truck-hire-js" */),
  "component---src-templates-vehicle-shifting-js": () => import("./../../../src/templates/vehicleShifting.js" /* webpackChunkName: "component---src-templates-vehicle-shifting-js" */)
}

